var APP = {
	// Data
    D: {
		// Новости
		allRecords: 0,
		nextRecords: 0,
	},
	// Views
    V: {},
	// Extensions
    E: {},
	// Utils
	U: {
		priceFormat: function(num) {
			var str = num.toString();
			return str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		},
		declOfNum: function (number, titles) {
			var cases = [2, 0, 1, 1, 1, 2];
			return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
		}
	},
    
    uploadPath: '/assets/upload/',
};